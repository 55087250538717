<!--
 * @Author: diaowangtao
 * @Date: 2020-08-21 14:14:04
 * @Description: 加入我们页面
-->
<template>
  <div class="join">
    <my-banner :bg_banner="bg_banner" v-if="bg_banner"></my-banner>
    <div class="w1200">
      <div class="join_top" v-html="$options.filters.sLang(headerContent)"></div>
      <div class="join_bottom">
        <div class="table" v-if="hasData==1">
          <div class="table_head">
            <span class="table_td1" v-html="$options.filters.sLang('职位名称')"></span>
            <span class="table_td2" v-html="$options.filters.sLang('职位类别')"></span>
            <span class="table_td3" v-html="$options.filters.sLang('工作地点')"></span>
            <span class="table_td4" v-html="$options.filters.sLang('发布时间')"></span>
          </div>
          <div
            class="table_tr hand"
            v-for="(item,index) in postList"
            :key="index"
            :class="[index%2 != 1?'':'table_bg']"
            @click="goToPage(item.id)"
            :id="item.id"
            htmdEvt="click_news"
          >
            <span class="table_td1">{{item.title|sLang}}</span>
            <span class="table_td2">{{item.type|sLang}}</span>
            <span class="table_td3">{{item.city|sLang}}</span>
            <span class="table_td4">{{item.time|sLang}}</span>
          </div>
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="10"
            layout="total, prev, pager, next"
            :total="totalSize"
            class="page"
            v-if="totalPage>1"
          ></el-pagination>
        </div>
        <div v-if="hasData==0">
          <img :src="require('@/assets/img/nodata.png')" alt class="nodata_img" />
          <p class="nodata_p">暂无信息</p>
        </div>
        <div class="message" v-html="$options.filters.sLang(footerContent)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { joinUsDetail, getIntroduction, getJobList } from '@/api/joinUs'
import myBanner from '@/components/myBanner/index.vue'
export default {
  name: 'join',
  data() {
    return {
      bg_banner: '',
      hasData: 1,
      content: ['', ''],
      pageNo: 1,
      postList: [],
      totalSize: '', //总条数
      totalPage: '', //总页数
      headerContent: '',
      footerContent: '',
    }
  },
  components: {
    myBanner: myBanner,
  },
  created() {
    joinUsDetail().then((res) => {
      this.bg_banner = res.coverList[1].oldUrl
    })
    getIntroduction({ pageNo: 1, pageSize: 10 }).then(res => {
      ['header', 'footer'].forEach(field => {
        this[`${field}Content`] =
          (
            res.list.find(item => {
              return item.article.pageTitle.includes(field)
            }) || {}
          ).article.content || ''
      })
    })
    

    this.getJob(this.pageNo)
  },
  mouned() {},
  methods: {
    handleCurrentChange(val) {
      //console.log(`当前页: ${val}`)
      this.getJob(`${val}`)
    },
    goToPage(id) {
      this.$router.push({ path: 'job', query: { id: id } })
    },
    getJob(pageNo) {
      getJobList({ pageNo: pageNo, pageSize: 10 })
        .then((res) => {
          //console.log(res)
          this.totalPage = res.totalPage
          this.totalSize = res.totalSize
          this.postList = res.list.map((item) => ({
            id: item.id,
            title: item.article.title,
            type: item.article.pageTitle,
            city: item.article.author,
            time: this.$dayjs(item.publishTime).format('YYYY-MM-DD'),
            content: item.article.digest,
          }))
        })
        .catch((res) => {
          this.hasData = 0
        })
    },
  },
}
</script>

<style lang='scss'>
.join {
  background: #f2f3f5;
  .w1200 {
    .join_top {
      background: #fff;
      padding: 40px 20px;
      font-size: 16px;
      color: #303a51;
      line-height: 25px;
      p:not(:first-child) {
        margin-top: 20px;
      }
    }
    .join_bottom {
      background: #fff;
      height: 940px;
      margin-top: 10px;
      position: relative;
      overflow: hidden;
      .message {
        padding: 30px 20px;
        font-size: 12px;
        color: #8d909b;
        text-align: justify;
        width: 100%;
        position: absolute;
        bottom: 0;
        p {
          margin-top: 15px;
        }
      }
      .table {
        overflow: hidden;
        .table_head {
          height: 89px;
          line-height: 76px;
          box-shadow: 0 0px 4px 0 rgba(141, 144, 155, 0.2);
          font-size: 16px;
          color: #575c6d;
        }
        .table_td1 {
          width: 550px;
          padding-left: 50px;
          float: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          height: 54px;
        }
        .table_td2 {
          width: 280px;
          float: left;
          height: 54px;
        }
        .table_td3 {
          width: 230px;
          float: left;
          height: 54px;
        }
        .table_tr {
          height: 54px;
          line-height: 54px;
          font-size: 14px;
          color: #303a51;
        }
        .table_bg {
          background: #faf4eb;
        }
      }
      .nodata_img {
        display: block;
        width: 282px;
        height: 300px;
        margin: 90px auto 30px auto;
      }
      .nodata_p {
        font-size: 18px;
        color: #8d909b;
        text-align: center;
        margin-bottom: 80px;
      }
    }
  }
  .el-pagination {
    text-align: center;
    margin-top: 30px;
    font-weight: 400;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    font-family: HelveticaNeue;
    margin: 0 5px;
    border: 1px #e5e5e5 solid;
    color: #303a51;
    min-width: 30px;
    border-radius: 4px;
    background: none;
  }
  .el-pagination.is-background .el-pager li:hover {
    color: #303a51;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #2074cc;
    color: #fff;
    border: 1px #2074cc solid;
  }
}
</style>